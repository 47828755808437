<template>
  <div>
    <v-radio-group :value="value" :disabled="isReadSurvey || options.readonly || nc" @change="onUpdateValue">
      <v-radio :value="true" :label="this.$t('survey.boolean.true')"/>
      <v-radio :value="false" :label="this.$t('survey.boolean.false')"/>
    </v-radio-group>
    <div class="switches">
      <v-switch :label="$t('survey.slider.nc')"
                v-if="!options.mandatory"
                v-model="nc"
                :disabled="isReadSurvey || na"
                class="switch"
      />
      <v-switch :label="$t('survey.slider.na')"
                v-if="!options.mandatory"
                v-model="na"
                :disabled="isReadSurvey || nc"
                class="switch"
      />
    </div>
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'
import ncMixin from '@/components/Survey/Reader/ncMixin'
import naMixin from '@/components/Survey/Reader/naMixin'

export default {
  name: 'Boolean',

  mixins: [questionMixins, ncMixin, naMixin],
}
</script>

<style lang="scss" scoped>
.v-input::v-deep {
  label {
    font-size: 18px;
    color: black;
  }
}
</style>

<style lang="scss" scoped>
@import "src/assets/style/style";

.switches > * {
  display: inline-flex;
  margin-right: 20px;
}

</style>
